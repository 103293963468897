@use "../scss/main-dart/global";
@use "../scss/main-dart/overrider";
@use "../scss/pages/toast";

$navbar-top-height-mobile: 0; // Set this to 0 if navbar top is not required
$navbar-top-height-desktop: 0; // Set this to 0 if navbar top is not required

$navbar-main-height-mobile: 67px; // Height on mobile
$navbar-main-height-desktop: 67px; // Height on desktop

$navbar-bottom-height-mobile: 60px; // Height on mobile
$navbar-bottom-height-desktop: 60px; // Height on desktop

$navbar-height-mobile: $navbar-top-height-mobile + $navbar-main-height-mobile;
$navbar-height-desktop: $navbar-top-height-desktop + $navbar-main-height-desktop;

// @use "../../components/global/Navbar/navbar";
// @use "../../components/global/Footer/footer";
// @use "../../components/global/CookieConsent/cookie-consent";

// @use "../../components/anti/Modal/modal-custom";
// @use "../../components/anti/Buttons/buttons";
// @use "../../components/anti/Breadcrumb/breadcrumb";
// @use "../../components/anti/Card/card";

// @use "../../components/anti/Image/image";

// Custom variables
// EDIT THESE VARIABLES
// @import "variables/navbar"; // Set navbar height, animation duration, and other variables

@use "../../components/anti/Navbar/navbar";
// @use "../../components/anti/navbar/navbar";
@use "../../components/anti/Image/image";
@use "../../components/anti/Modal/modal";
@use "../../components/anti/Modal/modal-custom";
@use "../../components/anti/Tabs/tabs";
@use "../../components/anti/Tabs/tabs-custom";
@use "../../components/anti/Button/button";
// @use "../../global/toast/toast";
@use "../../components/anti/Forms/forms";
@use "../../components/anti/Forms/forms-custom";

// User Style
@use "../scss/pages/login";
@use "../scss/pages/home";
@use "../scss/pages/success";
@use "../scss/pages/error";
// @use "../scss/pages/otp";
// @use "../scss/pages/barcode";
// @use "../scss/pages/empty-state";
// @use "../scss/pages/user-history";
// @use "../scss/pages/user-register";
// @use "../scss/pages/settings";

// Staff Style
// @use "../scss/pages/staff-auth";
// @use "../scss/pages/staff-home";
// @use "../scss/pages/staff-history";
// @use "../scss/pages/vouchers";

// Custom Mixins ================================================= //
// Custom Mixins ================================================= //

::-moz-scrollbar-button:decrement,
::-moz-scrollbar-button:increment,
::-webkit-scrollbar-button:decrement,
::-webkit-scrollbar-button:increment {
  width: 0px;
}

* {
  ::-webkit-scrollbar {
    display: none;
  }
}

.bg-layoutmobileframe {
  height: 100vh;
  width: 100vw;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 992px) {
    background-color: var(--gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 2rem 0;
  }
  .main-screen {
    overflow-x: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    width: 100%;
    height: 100%;
    background-color: var(--bg-primary);
    position: relative;
    overflow-y: hidden;
    &.open-drawer {
      overflow-y: hidden;
    }
    .screen {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }
    .full-loading-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--black);
      z-index: 3;
      opacity: 0.9;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .page-section {
      width: 100%;
      &::-webkit-scrollbar {
        display: none;
      }
      &.fixed-height {
        height: 100%;
      }
      @media (max-width: 992px) {
        width: 100%;
      }
      overflow-y: scroll;
    }
    .logo-header {
      position: sticky;
      z-index: 2;
      &.fixed-header {
        position: absolute;
        background: transparent;
      }
      .burger-menu {
        position: absolute;
        right: 1rem;
        top: 0px;
        height: 80%;
        display: flex;
        align-items: center;
        .menu-icon {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 4px;
          .line {
            height: 2px;
            background-color: var(--dark-red);
            width: 12px;
            &:nth-child(2) {
              width: 18px;
            }
          }
        }
      }
      background-color: var(--bg-primary);
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0.75rem;
    }
    @media (min-width: 992px) {
      border-radius: 16px;
      width: 412px;
      height: 100dvh;
      // height: calc(100vh - 4rem);
      overflow-x: hidden;
      overflow-y: hidden;
      position: relative;
      border: 1px solid var(--gray-100);
    }
  }
}

.pointer {
  cursor: pointer;
}

// ==========================================================================
// NProgress
// ==========================================================================

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  // background: var(--red);
  position: fixed;
  z-index: 1031;
  top: $navbar-height-mobile;
  width: 100%;
  height: 2px;
  @media (min-width: 512px) {
    height: 0;
    background: transparent;
  }
}
//LOADING FULL
.full-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0.9;
  height: 100%;
  background-color: var(--black);
}

.sc-voucher {
  height: 100%;
  .divider {
    height: 300px;
  }
  .voucher-content {
    height: 500px;
    background-color: red;
  }
}

// TYPOGRAPHY
.title-text {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--dark-red);
  line-height: 2rem;
  margin-bottom: 1rem;
  &.center {
    text-align: center;
  }
  &.uppercase {
    text-transform: uppercase;
  }
}

.text-gray {
  color: rgba(51, 51, 51, 1) !important;
  opacity: 0.7;
}

.form-placeholder {
  &::placeholder {
    font-weight: 100 !important;
    letter-spacing: 0.5px;
  }
}

.letter-normal {
  letter-spacing: 0.5px;
}

.modal-camera {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  .col-top {
    flex: 1;
  }
  .col-bottom {
    flex: 1;
  }
}

.success-reward {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .col-top {
    background-color: #f9aa11;
    height: 200px;
  }
  .col-bottom {
    position: relative;
    .success-img-wrapper {
      width: 100%;
      position: absolute;
      top: -60px;
      display: flex;
      justify-content: center;
    }
    flex: 1;
    background-color: var(--bg-primary);
    .info {
      height: 100%;
      position: relative;
      .info-title {
        color: var(--dark-red);
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
    }
    .btn-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
}

* {
  letter-spacing: 0.5px;
}

.input-prefix {
  padding: 2rem;
}

.input-box {
  display: flex;
  align-items: center;
  max-width: 300px;
  background: #fff;
  border: 1px solid #a0a0a0;
  border-radius: 4px;
  padding-left: 0.5rem;
  overflow: hidden;
  font-family: sans-serif;
}

.input-box .prefix {
  font-weight: 300;
  font-size: 14px;
  color: #999;
}

.input-box input {
  flex-grow: 1;
  font-size: 14px;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.5rem;
}

.input-box:focus-within {
  border-color: #777;
}

.drawingBuffer {
  position: absolute;
  top: 0;
  left: 0;
}
