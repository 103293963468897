.sc-success {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .top {
    flex: 1;
    height: 30vh;
    background-color: var(--yellow);
    display: flex;
    justify-content: center;
    align-items: end;
    .img-wrapper {
      position: relative;
      bottom: -25%;
    }
  }
  .bottom {
    flex: 2;
    background-color: transparent;
    .bottom-content {
      .title {
        font-size: 1.25rem;
        text-align: center;
        font-weight: 700;
        color: var(--dark-red);
        margin-bottom: 4px;
      }
      .description {
        font-size: 14px;
        text-align: center;
        color: rgba(79, 79, 79, 1);
      }
      padding-top: 5.5rem;
      padding-bottom: 1rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
