@use "../../plugins/anti-icons/variables" as vIcon;

.sc-staff-home {
  height: 100%;
}

.barcode-camera-wrapper {
  .camera-container {
    position: relative;
    border-radius: 10px;
    width: 100%;
    .info-scan {
      font-size: 14px;
    }
    .close-camera {
      min-height: 150px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(130, 130, 130, 1);
      position: relative;
    }
    .btn-try {
      font-weight: 700;
      color: var(--white);
      border: 2.5px solid var(--white);
      border-radius: 8px;
      background: transparent;
      padding: 0.6rem 0;
      font-size: 12px;
    }
    .overlay-camera {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--black);
      z-index: 1000000;
      opacity: 0.9;
      border-radius: 10px;
      .icon-success {
        border-radius: 50%;
        color: var(--white);
        width: 25px;
        height: 25px;
      }
      .error-text {
        font-size: 1.125rem;
      }
    }
    video {
      border-radius: 10px;
    }
  }
  .title {
    line-height: 24px;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-style: normal;
  }
}

.sc-history {
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    .header-title {
      margin: 0;
      padding: 0;
      font-size: 1.25rem;
      font-weight: 700;
      letter-spacing: 0.05em;
      color: var(--dark-red);
    }
    .filter-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      width: 100px;
      height: 32px;
      box-sizing: border-box;
      border: 1.75px solid var(--dark-red);
      border-radius: 50px;
      span {
        font-size: 12px;
        text-transform: uppercase;
        color: var(--dark-red);
        font-weight: 700;
      }
    }
  }

  .black-state {
    flex: 1;
    .image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.5;
      img {
        object-fit: contain;
      }
    }
    .info {
      .title {
        margin-top: 0.5rem;
        color: var(--black);
        font-size: 1.25rem;
        font-weight: 700;
      }
      .desc {
        color: var(--gray-2);
        font-size: 0.875rem;
        opacity: 0.7;
      }
    }
  }
}

.item-list {
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .item {
    // &::after {
    //   font-family: "Anti Icons Regular";
    //   content: vIcon.varIcon(vIcon.$ai-circle);
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    // }
    padding-top: 0.875rem;
    .date-label {
      font-size: 10px;
      opacity: 0.5;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.05em;
      margin-bottom: 5px;
    }
    .item-info {
      position: relative;
      .item-top {
        display: flex;
        justify-content: space-between;
        .item-top-right {
          .stamp-amount {
            font-size: 12px;
            text-transform: uppercase;
            color: var(--gold);
            font-weight: 700;
            span {
              color: var(--black);
            }
          }
        }
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
      }
      border-bottom: 0.2px solid rgba(91, 23, 28, 0.2);
      padding: 0 0 0.875rem 0;
      p {
        margin: 0;
      }
      .title {
        font-size: 14px;
        opacity: 0.8;
        line-height: 150%;
        font-weight: 100;
        letter-spacing: 0.05em;
        color: rgb(102, 102, 102);
      }
      .description {
        font-size: 12px;
        color: var(--dark-red);
      }
    }
  }
}
