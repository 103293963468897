$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

.modal-forgot-password {
  .title {
    font-size: 1.25rem;
    color: var(--dark-red) !important;
  }
  .description {
    padding-top: 1.5rem;
    p {
      color: rgba(79, 79, 79, 1) !important;
      letter-spacing: 0.05em;
      font-size: 1rem;
    }
  }
  .btn-forgot {
    text-transform: uppercase;
  }
}

.modal-bottom-position {
  @media (max-width: 512px) {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
  }
}

.modal-reward {
  min-height: 30vh;
  position: relative;
  .description {
    max-height: 75vh;
    overflow: auto;
    padding-bottom: 5rem !important;
    .stampvoucher {
      .label {
        font-size: 0.75rem;
        opacity: 0.7;
        margin: 0;
        margin-bottom: 4px;
        max-width: 50%;
      }
      .info-label {
        color: var(--black);
        font-size: 0.8125rem;
        max-width: 50%;
      }
      .info-Price {
        color: var(--dark-red);
        font-size: 1.25rem;
        font-weight: 700;
      }
      .stampvoucher-title {
        text-align: center;
        font-size: 1.5rem;
        color: var(--dark-red);
        font-weight: 700;
      }
      .stampvoucher-header {
        &.no-border {
          border: none;
        }
        border-bottom: 0.5px solid rgba(91, 23, 28, 0.2);
        display: flex;
        justify-content: space-between;
        .info-label, .label {
          max-width: 100%;
        }
      }
      .text-total {
        border: none !important;
        background: transparent !important;
      }
      .stampvoucher-body {
        .voucher-item {
          padding: 1rem 0 1rem 0;
          border-bottom: 0.5px solid rgba(91, 23, 28, 0.2);
          display: flex;
          justify-content: space-between;
          .label {
            text-align: right;
          }
        }
      }
    }
    .button-wrapper {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      padding: 1rem;
      padding-bottom: 1.75rem;
      background-color: var(--bg-primary);
    }
  }

  .modal-reward_wrapper-img {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -100px;
  }
  .title {
    color: var(--dark-red);
    font-size: 1.5rem;
    font-weight: 700;
  }
  .description-text {
    line-height: 24px;
    font-size: 1rem;
  }
}

.offside-top {
  top: -2rem !important;
  color: var(--white);
  font-size: 2rem !important;
  font-weight: bold !important;
}

.modal_terms_conditions {
  .main-title {
    color: var(--dark-red);
  }
  .stamp-reward {
    .title {
      font-weight: bold;
      font-size: 1.25rem;
      color: var(--dark);
    }
    ul {
      padding: 0 0 0 2rem;
      li {
        margin-top: 0.5rem;
        color: #4f4f4f;
        font-size: 1rem;
      }
    }
  }
}

.modal-image-offset {
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.modal_detail {
  position: relative;
  text-align: center;
  padding: 96px 0 0;
  .list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    padding-top: 0.75rem;
    &.bordered {
      border-bottom: 0.5px solid rgba(91, 23, 28, 0.2);
      padding-bottom: 0.1rem;
    }
    &.voucher {
      border-bottom: 0.5px solid rgba(91, 23, 28, 0.2);
      padding-top: 0;
    }
    .list-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .label {
        font-size: 0.75rem;
        letter-spacing: 0.05em;
        // color: var(--gray-3);
        color: #828282;
        font-size: 12px;
        font-family: "Brown";
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0.5rem;
      }
      .text {
        font-size: 1rem;
        // color: var(--dark);
        margin-bottom: 14px;
        line-height: 150%;
        color: #1a1a1a;
        text-align: right;
        letter-spacing: 0.05em;
        &.total {
          color: var(--dark-red);
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          border: none !important;
          text-align: right;
          letter-spacing: 0.05em;
        }
      }
      .price {
        font-size: 1.25rem;
        color: var(--dark-red);
        margin-bottom: 0.75rem;
        font-weight: 700;
      }
      &.end {
        align-items: flex-end;
      }
    }
  }
  .modal_detail-action {
    padding: 1rem 0 1.5rem;
  }
}

.modal-voucher {
  background: transparent !important;
  .close {
    display: none;
  }
  .modal-voucher_content {
    background: transparent !important;
  }
  .modal-voucher_content_wrapper {
    .content-wrapper-top {
      background-image: url("/img/common//substract-top.png") !important;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      min-height: 210px;
    }
    .content-wrapper-bottom {
      background-image: url("/img/common//substract-bottom.png") !important;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 175px;
    }
  }
}

.modal-cashier-modal-camera {
  @media (max-width: $sm) {
    padding: 0 !important;
    margin: 0;
    .close {
      display: none !important;
    }
    .modal-wrapper-cashier-camera {
      padding: 0;
      margin: 0;
      height: 100%;
      .modal-wrapper-cashier-camera-content {
        height: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        .col-top {
          position: relative;
          .overlay-frame {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .overlay-top,
            .overlay-bottom {
              flex: 1;
            }
            .overlay-top,
            .overlay-bottom {
              background: var(--black);
              opacity: 0.6;
            }
            .overlay-middle {
              box-sizing: border-box;
              height: 170px;
              display: flex;
              .box-1,
              .box-3 {
                width: 50px;
                background: var(--black);
                opacity: 0.6;
              }
              .box-2 {
                box-sizing: border-box;

                flex: 1;
                position: relative;
                .box-top-left,
                .box-top-right,
                .box-bottom-left,
                .box-bottom-right {
                  box-sizing: border-box;
                  position: absolute;
                  width: 50px;
                  height: 50px;
                  z-index: 100;
                }
                .box-top-left {
                  left: -5px;
                  top: -5px;
                  border-top: 5px solid var(--red);
                  border-left: 5px solid var(--red);
                }
                .box-top-right {
                  right: -5px;
                  top: -5px;
                  border-top: 5px solid var(--red);
                  border-right: 5px solid var(--red);
                }
                .box-bottom-left {
                  bottom: -5px;
                  left: -5px;
                  border-bottom: 5px solid var(--red);
                  border-left: 5px solid var(--red);
                }
                .box-bottom-right {
                  bottom: -5px;
                  right: -5px;
                  border-bottom: 5px solid var(--red);
                  border-right: 5px solid var(--red);
                }
              }
            }
          }
        }
        .col-bottom {
        }
      }
    }
  }
}

#barcode_scanner {
  &::ng-deep video {
    width: 100%;
  }
  ::ng-deep canvas {
    position: absolute;
    z-index: 1000;
    right: 0;
    width: 100%;
  }
}
// canvas.drawing,
// canvas.drawingBuffer {
//   position: absolute;
//   left: 0;
//   top: 0;
// }

.modal-camera-cashiers {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg-primary);
  z-index: 100;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  .camera-wrapper {
  }
  .scanner-info {
    flex: 1;
    position: relative;
    width: 100%;
    .info {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding-top: 3rem;
      .title {
        color: var(--dark-red);
        font-size: 1.5rem;
        font-weight: 700;
        letter-spacing: 0.5px;
        font-style: normal;
        line-height: 31px;
        text-align: center;
        letter-spacing: 0.05em;
      }
      .description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        /* or 21px */
        text-align: center;
        letter-spacing: 0.05em;
        opacity: 0.7;
      }
    }
    .form-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: var(--bg-primary);
    }
    .close {
      position: absolute;
      right: 1rem;
      top: 5px;
      i {
        font-weight: 100;
        font-size: 1.25rem;
      }
    }
  }
}

.modal-give-reward {
  .title,
  .info {
    letter-spacing: 0.5px;
  }
  .title {
    font-size: 1.5rem;
    color: var(--dark-red);
    font-weight: bold;
  }
  .info {
  }
}

.modal_detail-wrapper {
  max-height: 65vh;
  overflow-y: scroll;
}

.modal-forgot {
  .forgot-title {
    color: var(--dark-red);
  }
}

.modal-confirm {
  min-height: 35vh;
  display: flex;
  justify-content: end;
  flex-direction: column;
  .title {
    text-align: center;
    color: var(--dark-red);
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .description {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 2.5rem;
  }
}

.filter-modal {
  padding-top: 1.25rem;
  min-height: 30vh;
  .filter-text {
    color: var(--dark-red);
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .date-filter-wrapper {
    .date-title {
      color: var(--black);
      font-weight: 700;
    }
  }
}
