$light-green: rgba(39, 174, 96, 1) !default;
$red-grad: #e42c6e;
$ease-cubic-bezier: cubic-bezier(0.55, 0.085, 0.68, 0.53) !default; // Antikode
$transition-base: all 0.2s $ease-cubic-bezier;

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

.toast-wrapper {
  z-index: 10000000;
  position: fixed;
  width: 100%;
  max-width: 465px;
  padding: 0.7rem;
  // transition: $transition-opacity;
  transition: $transition-base;
  color: var(--white);
  opacity: 0;
  .message-toast {
    font-size: 10rem;
  }
  visibility: hidden;
  @media (min-width: $md) {
    right: 1rem;
  }
  &.show {
    // padding: 0;
    opacity: 1;
    visibility: visible;
    // top: -$navbar-main-height-desktop;
  }
  .toast-state {
    @media (min-width: $sm) {
      width: 355px;
    }
    position: relative;
    border-radius: 5px;
    height: 100%;
    padding: 0.8rem 2rem 0.8rem 1rem;
    background-color: transparent;
    transition: background-color 0.2s $ease-cubic-bezier;
    p {
      margin: 0;
    }
    &.success {
      // background-color: $green;
      background-color: $light-green;
    }
    &.danger {
      // background-color: $red;
      background-color: #eb5757;
    }
    &.error {
      background-color: $red-grad;
    }
    &.warning {
      // background-color: $warning;
    }
    &.verify {
      background-color: var(--primary);
    }
    &.loading {
      background-color: var(--gray-600);
    }
    &.nav-toast {
      top: 67px;
      // @include media-breakpoint-up($navbar-expand-up) {
      //   top: $navbar-height-desktop;
      // }
    }

    .close-toast {
      i {
        font-size: 1.5rem;
      }
      position: absolute;
      right: 1rem;
      top: 10%;
      display: flex;
      justify-content: center;
    }
  }
  // @include media-breakpoint-up($navbar-expand-up) {
  //   top: $navbar-main-height-desktop;
  // }
}
