@use "../../plugins/anti-icons/variables" as vIcon;
@use "../main-dart/variables/grid" as grid;

section,
footer {
  max-width: 1440px;
  margin: auto;
  position: relative;
}

.light,
.sc-light,
.bg-light {
  p,
  a,
  span,
  strong {
    color: var(--black);
  }
}

.img-cover {
  object-fit: cover;
}

.swiper-visible {
  width: 100%;
  overflow: visible !important;
}

.swiper {
  &.rounded-navigation {
    &.rounded-navigation-hover {
      @media (min-width: grid.$md) {
        &:hover {
          .swiper-button-next,
          .swiper-button-prev {
            opacity: 1;
          }

          .swiper-button-prev {
            left: calc(var(--swiper-navigation-size) * 2.5);
            transform: translate3d(0, 0, 0);
          }

          .swiper-button-next {
            right: calc(var(--swiper-navigation-size) * 2.5);
            transform: translate3d(0, 0, 0);
          }
        }
      }

      .swiper-button-prev {
        @media (min-width: grid.$md) {
          left: calc(var(--swiper-navigation-size) * 2.5);
          transform: translate3d(-2rem, 0, 0);
        }
      }

      .swiper-button-next {
        @media (min-width: grid.$md) {
          right: calc(var(--swiper-navigation-size) * 2.5);
          transform: translate3d(2rem, 0, 0);
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

        @media (min-width: grid.$md) {
          opacity: 0;
        }
      }
    }

    .swiper-button-prev {
      left: 10px;

      &::after {
        content: vIcon.varIcon(vIcon.$ai-chevron-left);
        right: 2px;
      }
    }

    .swiper-button-next {
      right: 10px;

      &::after {
        content: vIcon.varIcon(vIcon.$ai-chevron-right);
        left: 2px;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      background-color: var(--blue);
      border-radius: 100%;
      width: 30px;
      height: 30px;
      display: none;

      @media (min-width: grid.$md) {
        width: 60px;
        height: 60px;
        display: flex;
      }

      &::after {
        font-family: "Anti Icons Regular";
        color: var(--white);
        position: relative;
        font-size: 0.75rem;

        @media (min-width: grid.$md) {
          font-size: 1.5rem;
        }
      }
    }
  }

  &.swiper-rounded {
    border-radius: 1rem;
  }
}

.package-tables {
  .package-tables-content {
    display: flex;
    border-radius: 1rem;
    width: 100%;

    & > div {
      flex: 1 1 auto;
    }

    .swiper-slide {
      height: 100%;
      transform: none;

      & > div {
        height: 100%;
      }

      &:last-child {
        .package-tables__item {
          @media (min-width: grid.$md) {
            border-radius: 0 1rem 1rem 0;
          }
        }
      }

      &:first-child {
        width: 225px;
        display: none;

        @media (min-width: grid.$md) {
          display: block;
        }
      }
    }

    .package-tables__item {
      background-color: var(--white);

      h2,
      h3 {
        color: var(--blue);
      }

      span,
      p,
      strong {
        color: var(--black);
      }

      // &:last-child {
      //   @media (min-width: grid.$md) {
      //     border-radius: 0 1rem 1rem 0;
      //   }
      // }

      // &:first-child {
      //   .package-tables__item-footer,
      //   .package-tables__item-header {
      //     border-left: none;
      //   }
      // }

      &.package-tables__item--best-seller {
        // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
        position: relative;
        z-index: 1;

        .package-badge {
          position: absolute;
          right: -0.75rem;
          top: -0.75rem;
        }
      }

      &.package-tables__item--reference {
        background-color: #ecf9ff;
        text-align: left;
        // max-width: 225px;
        min-width: 225px;
        width: 100%;

        @media (min-width: grid.$md) {
          border-radius: 1rem 0 0 1rem;
        }

        .package-tables__item-header {
          border-bottom: 1px solid #e2e2e2;
          text-align: left;

          img {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
          }
        }

        .package-tables__item-header,
        .package-tables__item-footer {
          padding: 1rem;
        }

        .package-tables__item-body {
          text-align: left;

          .package-tables__item-body--item {
            display: flex;
            strong {
              flex: 1;
            }
          }
        }
      }

      .package-tables__item-header {
        height: 285px;
        border-bottom: 1px solid #e2e2e2;
        text-align: center;
        border-left: 1px solid #e2e2e2;

        img {
          border-radius: 1rem 1rem 0 0;
        }

        .package-prices {
          margin-bottom: 1rem;

          strong {
            color: var(--black);
            display: block;
            font-size: 20px;

            span {
              font-size: 14px;
              font-weight: normal;
            }
          }

          small {
            color: #091e40;
            text-decoration: line-through;
          }
        }

        button {
          span {
            color: var(--white) !important;
          }
        }
      }

      .package-tables__item-body {
        border-left: 1px solid #e2e2e2;
        text-align: center;

        .package-tables__item-body--item {
          border-bottom: 1px solid #e2e2e2;
        }
      }

      .package-tables__item-footer {
        padding: 1rem;
        border-left: 1px solid #e2e2e2;
        text-align: center;

        button {
          color: var(--blue);

          &::before {
            background-color: var(--blue);
          }
        }
      }

      .package-tables__item-body--item {
        padding: 1rem;
      }
    }
  }
}

.lists {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .list__item {
    display: flex;
    gap: 1rem;
    align-items: center;

    .list__desc {
      .list__label {
        font-size: 12px;
        color: var(--black);
        opacity: 0.5;
      }

      .list__text {
        font-size: 14px;
      }
    }
  }
}

.cover {
  height: 540px;
  width: 100%;

  & + section {
    margin-top: -10rem;
    padding-bottom: 5rem;
  }

  .bg-cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  h1 {
    font-size: 40px;
  }

  .cover-label {
    margin-bottom: 1rem;

    span {
      font-size: 18px;
    }
  }
}

.post-content {
  margin-bottom: 5rem;
  p {
    color: #131313;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
  }
}

.box-wrapper {
  border-radius: 12px;
  padding: 2px;
  margin-bottom: 1rem;
  cursor: pointer;

  .box {
    border-radius: 10px !important;
  }

  &.active {
    border: none !important;
    box-shadow: none !important;
  }

  &.box-wrapper-hover--scale {
    transform: scale(1);
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.025);
    }
  }

  &.box-wrapper-gradient-primary {
    padding: 4px;

    &.active {
      background: linear-gradient(180deg, #01dc97 0%, #1d90c9 100%);
    }
  }

  &.box-wrapper-gradient-secondary {
    &.active {
      background: linear-gradient(180deg, #18448a 0%, #1d90c9 100%);
    }
  }

  .box {
    padding: 1rem;
    background-color: var(--white);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &.box-border {
      border: 2px solid #e2e2e2;
    }

    &.box-rounded {
      border-radius: 12px;
    }

    &.box-shadow {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    }
  }
}

.navigation {
  .navigation-content {
    border-left: 1px solid rgba(217, 217, 217, 0.5);
  }
}

.accordion {
  .accordion__item {
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    background-color: var(--white);
    overflow: hidden;
    margin-bottom: 2rem;

    &.active {
      .accordion-header {
        .accordion-header__chevron {
          transform: rotate(180deg);
        }
      }

      .accordion-content {
        max-height: fit-content;
      }
    }

    .accordion-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem;
      cursor: pointer;

      .accordion-header__text {
        display: flex;
        flex-direction: column;
      }

      span {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      strong {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
      }

      .accordion-header__chevron {
        color: #007bb6;
        transform: rotate(0deg);
        transition: transform 0.2s ease-in-out;
      }
    }

    .accordion-content {
      padding: 0 1.5rem;
      max-height: 0;
      transition: max-height 0.2s ease-in-out;

      p {
        color: #68788a;
      }
    }
  }
}
