// Text Area
textarea {
  resize: none;
}

// Form Text
.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--gray-400);
}
.invalid-feedback,
.valid-feedback {
  display: block;
}
.invalid-feedback {
  @extend .form-text;
  //   color: $danger;
}
.valid-feedback {
  @extend .form-text;
  //   color: $success;
}

// Input Group
.input-group {
  > .input-group-prepend {
    > .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  > .input-group-append {
    > .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  > .field {
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:last-child) {
      .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.show-pass-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  i {
    color: var(--black);
  }
}
svg {
  color: var(--black) !important;
}

.form-control-wrapper {
  position: relative;
}

// Field

.field {
  label {
    font-size: 1rem;
    color: var(--white);
    opacity: 0.5;
  }
  &.dark {
    label {
      color: var(--gray-3);
      opacity: 1;
    }
  }
}

input {
  border: 1px solid var(--gray-200) !important;
  border-radius: 5px !important;
  &:focus {
    border: 1px solid var(--gray-200) !important;
    border-radius: 5px !important;
  }
}

.voucher-error-text {
  font-size: 10px;
  padding-left: 1px;
  padding-top: 2px;
  color: var(--red);
}

.input-with-prefix-suffix {
  position: relative;
  .error-border {
    border: 1px solid var(--red) !important;
  }
}
