/*
 * Antikode anti.scss
 * Version - 4.0.1
 * Bootstrap - 4.5.0
 * 2021 Antikode
 */

// ========================================================================================================================================
// Import
// ========================================================================================================================================

// ==========================================================================
// Bootstrap Base & Custom Variables
// ==========================================================================

// Required functions / mixins used in variables config
@import "../../../node_modules/bootstrap/scss/functions"; // DO NOT EDIT
@import "functions/strip-sass"; // e.g. strip unit in SASS
@import "mixins/fonts-custom"; // DO NOT EDIT

// // Custom variables
// // EDIT THESE VARIABLES
@import "variables/color"; // Set base color variables
@import "variables/options"; // Enable base rounded / shadows
@import "variables/spacing-grid";
@import "variables/border-shadow-modal"; // Set base border, box shadow, and modal variables
@import "variables/typography"; // Set custom fonts and base typography variables
@import "variables/buttons-forms"; // Set buttons / forms base padding and other variables
@import "variables/breadcrumbs";
@import "variables/transitions";
@import "variables/animations"; // Set general animation duration
// // @import "variables/navbar"; // Set navbar height, animation duration, and other variables

// Base styles
@import "base/bootstrap.scss"; // Bootstrap 4 base variables, mixins, and styles

// ==========================================================================
// Optional Plugins
// ==========================================================================

// Anti Icons 1.0.0
@import "../plugins/anti-icons/variables";
@import "../plugins/anti-icons/light/style.css";
@import "../plugins/anti-icons/regular/style.css";
@import "../plugins/anti-icons/solid/style.css";
@import "../plugins/anti-icons/brands/style.css";

// Eatlah Icons

@import "../plugins/eatlah-icons/style.css";

// ==========================================================================
// Antikode Custom Mixins, Utilities, Overriders, and Base
// ==========================================================================

// Custom Mixins
@import "mixins/gradients"; // Gradients mixns
@import "mixins/icons"; // Font awesome mixins
@import "mixins/prefix"; // Vendor-prefixed CSS helper
@import "mixins/position"; // Shorthandizes position declarations
@import "mixins/text-truncate"; // Text truncate multiline

// Utilities
@import "utilities/background"; // e.g. .bg-overlay
@import "utilities/buttons"; // e.g. .btn-block-mw-100px, .btn-block-mw-200px, etc.
@import "utilities/grid"; // e.g. .row-0, .row-1, .row-10px, .row-20px, etc.
@import "utilities/position"; // e.g. .center-both, etc.
@import "utilities/sizing"; // e.g. .h-50px, .w-md-100-px, etc.
@import "utilities/spacing"; // e.g. .py-main, .mb-sm-down-1, etc.
@import "utilities/text"; // e.g. .text-truncate-twoline, etc.

// SCSS base component
// @import "base/alert";
@import "base/animations";
@import "base/background";
// @import "base/dropdown";
// @import "base/forms";
// @import "base/nav";
// @import "base/post";
@import "base/reboot";
// @import "base/table";

// Components
// @import "../../components/anti/Modal/modal";

// Additional Root Variables
// :root {
//   --font-family-sans-serif-axiata: "Axiata", -apple-system, BlinkMacSystemFont,
//     "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
//     "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// }

#__next {
  width: 100vw;
  overflow: hidden;
}

// #nprogress {
//   pointer-events: none;
// }

// #nprogress .bar {
//   background: $secondary;

//   position: fixed;
//   z-index: 1031;
//   top: 0;
//   left: 0;

//   width: 100%;
//   height: 2px;
// }

// /* Fancy blur effect */
// #nprogress .peg {
//   display: block;
//   position: absolute;
//   right: 0px;
//   width: 100px;
//   height: 100%;
//   box-shadow: 0 0 10px $secondary, 0 0 5px $secondary;
//   opacity: 1;

//   -webkit-transform: rotate(3deg) translate(0px, -4px);
//   -ms-transform: rotate(3deg) translate(0px, -4px);
//   transform: rotate(3deg) translate(0px, -4px);
// }

// /* Remove these to get rid of the spinner */
// #nprogress .spinner {
//   display: block;
//   position: fixed;
//   z-index: 1031;
//   top: 15px;
//   right: 15px;
// }
