.sc-login-page {
  height: 100%;
  .login-formbox {
    width: 100%;
    .title {
      font-size: 1.5rem;
      color: var(--dark-red);
    }
    .form-box {
      width: 100%;
      .forgot-password-text {
        color: var(--black);
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
    }
  }
}
