.nav-drawer {
  transform: translateX(135%);
  transition-property: transform;
  transition-duration: 1s;
  transition-timing-function: ease(in-out-quart);
  &.open {
    transform: translateX(0%);
  }
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-primary);
  z-index: 1;
  overflow-y: hidden !important;
  .drawer-content {
    .title-username {
      color: var(--black);
      font-size: 1.5rem;
      font-weight: bold;
      margin: 0;
    }
    .title-branch {
      text-transform: uppercase;
      text-align: center;
      margin-top: 0.5rem;
      font-weight: lighter;
      font-size: 14px;
      color: rgba(130, 130, 130, 1);
      line-height: 150%;
      letter-spacing: 0.05em;
    }
    .navigation-list {
      display: flex;
      justify-content: center;
      padding: 1.25rem 0;
      .nav-list {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 1.75rem;
        .nav-list-item {
          cursor: pointer;
          color: var(--black);
          font-size: 1.125rem;
          text-align: center;
          text-transform: uppercase;
          list-style: none;
        }
      }
    }
  }
}

// .nav-top {
//   padding: 0 !important;
//   &.with-bg {
//     -webkit-transition: background 0.7s linear;
//     -moz-transition: background 0.7s linear;
//     -o-transition: background 0.7s linear;
//     -ms-transition: background 0.7s linear;
//     transition: background 0.7s linear;
//     background: var(--bg-primary);
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
//       rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
//     .wrapper {
//       border-bottom: 0;
//       .burger-menu {
//         .line {
//           background-color: var(--dark) !important;
//         }
//       }
//       .col-right {
//         i {
//           color: var(--dark) !important;
//         }
//       }
//     }
//   }
//   // background-color: var(--bg-primary);
//   position: -webkit-sticky; /* Safari */
//   position: sticky;
//   top: 0;
//   &.light {
//     .wrapper {
//       .burger-menu {
//         .line {
//           background-color: var(--white);
//         }
//       }
//       .col-right {
//         i {
//           color: var(--white);
//         }
//       }
//     }
//   }
//   &.nav-staff {
//     .wrapper {
//       border-bottom: unset;
//       height: 81px;
//     }
//   }
//   .wrapper {
//     height: 67px;
//     width: 100%;
//     border-bottom: 1px solid rgba(91, 23, 28, 0.2);
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     .col-right {
//       &.staff-menu {
//         height: 100%;
//         padding-top: 1.5rem;
//       }
//       i {
//         font-size: 1.2rem;
//         color: var(--dark);
//       }
//     }
//     .burger-menu {
//       display: flex;
//       flex-direction: column;
//       gap: 4px;
//       align-items: flex-end;
//       .line {
//         height: 2px;
//         background-color: var(--dark);
//         width: 18px;
//       }
//       &.nav-staff {
//         .line {
//           height: 2px;
//           background-color: var(--dark-red);
//           width: 12px;
//           &:nth-child(2) {
//             width: 18px;
//           }
//         }
//       }
//     }
//     .nav-staff-label {
//       color: var(--dark-red);
//       text-align: center;
//       font-weight: 700;
//       font-size: 1rem;
//       padding-top: 0.5rem;
//     }
//   }
// }

// ==========================================================================
// Drawer Menu
// ==========================================================================
.side-drawer-mobile {
  .staff-data {
    margin-top: 30px;
    .staff-name {
      font-weight: 700;
      letter-spacing: 0.5px;
      font-size: 1.5rem;
      margin: 0;
    }
    .staff-branch {
      color: #828282;
      text-transform: uppercase;
      margin-top: 5px;
      font-size: 12px;
      letter-spacing: 0.5px;
    }
  }
  &.existing {
    background-color: var(--black);
    color: var(--white);
    .menu-item {
      color: var(--white) !important;
    }
  }
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  top: 0;
  position: fixed;
  height: 100%;
  overflow: auto;
  width: 100%;
  z-index: 1039;
  // transform: translateX(-100%);
  // transition-property: transform;
  // transition-duration: 1s;
  // transition-timing-function: ease(in-out-quart);
  background-color: rgba(245, 242, 237, 1);
  &.account-menu {
    transform: translateX(135%);
    transition-property: transform;
    transition-duration: 1s;
    transition-timing-function: ease(in-out-quart);
    &.open {
      // transform: translateX(0%);
    }
  }
  @media (min-width: 512px) {
    display: none;
  }
  .main-drawer-wrapper {
    position: relative;
    .menu {
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      .menu-item {
        padding: 0.75rem 0;
        font-size: 1.5rem;
        text-transform: uppercase;
        text-align: center;
        color: var(--black);
      }
    }
  }
  .drawer-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67px;
    border-bottom: 1px solid var(--light-dark);
    position: relative;
  }
  &.open {
    transform: translateX(0);
  }
  .account-button {
    position: absolute;
    left: 1.5rem;
    i {
      font-size: 1.5rem;
    }
  }
  .close-button {
    position: absolute;
    right: 1.5rem;
    i {
      font-size: 1.5rem;
    }
  }
}

.times-icon {
  position: absolute;
  top: 0.75rem;
  left: 1.5rem;
  font-size: 1.5rem;
  display: flex;
  z-index: 100000;
}

.account-icon {
  z-index: 100000;

  position: absolute;
  top: 0.75rem;
  right: 1.25rem;
  font-size: 1.25rem;
  display: flex;
}
