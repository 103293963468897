// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// Override style on .modal-custom.scss instead
// unless you want to upgrade the component version

// ========================================================================================================================================
// Modal
// ========================================================================================================================================
$zindex-modal-backdrop: 1040;
$ease-cubic-bezier: cubic-bezier(0.55, 0.085, 0.68, 0.53) !default; // Antikode
$ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1) !default; // Antikode
$ease-in-out-quart: cubic-bezier(0.76, 0, 0.24, 1) !default; // Antikode
$zindex-modal: 1050;
$font-weight-normal: 400;

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

.ReactModal__Overlay {
  z-index: $zindex-modal-backdrop;
  //   background: var(--dark) !important;
  background: rgba(black, 0.7) !important;
  outline: none !important;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  &.ReactModal__Overlay--after-open {
    animation: fadeIn 0.2s $ease-cubic-bezier;
    opacity: 1;
  }
  &.ReactModal__Overlay--before-close {
    animation: fadeOut 0.2s $ease-cubic-bezier;
    opacity: 0;
  }
  .ReactModal__Content {
    outline: none !important;
    background: transparent !important;
    border-radius: 0 !important;
    border: none !important;
    position: relative !important;
    width: auto !important;
    padding: 0 0.7rem;
    inset: initial !important;
    z-index: $zindex-modal;
    margin: 0 auto;
    pointer-events: none;
    transform: translate(0, 0);
    width: 100%;
    // Centered by default
    display: flex;
    align-items: flex-start;
    min-height: calc(100% - (1.75rem * 2));
    &.centered {
      align-items: center;
    }
    &::-webkit-scrollbar {
      display: none; // Remove scrollbar
    }
    @media (max-width: $md) {
      max-width: 500px;
    }
    // Utilities classes for ReactModal__Content
    &.modal-md {
      @media (min-width: $md) {
        max-width: 650px !important;
      }
    }
    &.modal-lg {
      @media (min-width: $md) {
        max-width: 650px !important;
      }
      @media (min-width: $lg) {
        max-width: 850px !important;
      }
    }
    &.modal-xl {
      @media (min-width: $md) {
        max-width: 650px !important;
      }
      @media (min-width: $lg) {
        max-width: 850px !important;
      }
      @media (min-width: $xl) {
        max-width: 1000px !important;
      }
    }
    &.modal-xxl {
      @media (min-width: $md) {
        max-width: 650px !important;
      }
      @media (min-width: $lg) {
        max-width: 850px !important;
      }
      @media (min-width: $xl) {
        max-width: 1000px !important;
      }
      @media (min-width: $xxl) {
        max-width: 1200px !important;
      }
    }
  }
}

// Modal wrapper padding (by default based by .p-box
// Set these variables to set padding and modal close position automatically
$modal-padding-sm: 1.5rem;
$modal-padding-lg: 2rem;
$headings-line-height: 1.2;

.modal-wrapper {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--white);
  background-clip: padding-box;
  outline: 0;
  margin: 0.5rem 0;
  @media (min-width: $sm) {
    margin: 2rem 0;
  }
  .close {
    float: none;
    text-shadow: none;
    font-weight: $font-weight-normal;
    opacity: 1;
    cursor: pointer;
    position: absolute;
    width: 2rem;
    height: 2rem;
    font-size: 1.25rem;
    line-height: 2rem;
    text-align: center;
    top: $modal-padding-sm;
    right: $modal-padding-sm;
    margin-top: -($modal-padding-sm / 2);
    margin-right: -($modal-padding-sm / 2);
    z-index: 1;
    @media (max-width: $md) {
      top: $modal-padding-lg;
      right: $modal-padding-lg;
      margin-top: -($modal-padding-lg / 2);
      margin-right: -($modal-padding-lg / 2);
    }
    //  @include hover-focus-active {
    //    opacity: 1 !important;
    //  }
  }
  .modal-body {
    padding: 0;
    .modal-content {
      background-color: var(--bg-primary);
      border: none;
      padding: $modal-padding-sm;
      box-shadow: none;
      @media (max-width: $md) {
        padding: $modal-padding-lg;
      }
    }
  }
  .modal-title {
    margin-bottom: 0.5rem;
    line-height: $headings-line-height;
  }
}
