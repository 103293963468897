// Color system
// Version 2.0.0

/* Normal Gray */
// $white:    #fff;
// $gray-10:  #fcfcfc !default; // Antikode
// $gray-20:  #fafafa !default; // Antikode
// $gray-50:  #f2f2f2 !default; // Antikode
// $gray-70:  #f9f9f9 !default; // Antikode
// $gray-100: #e5e5e5 !default; // Antikode
// $gray-200: #cccccc !default; // Antikode
// $gray-300: #b3b3b3 !default; // Antikode
// $gray-400: #999999 !default; // Antikode
// $gray-500: #808080 !default; // Antikode
// $gray-600: #666666 !default; // Antikode
// $gray-700: #4d4d4d !default; // Antikode
// $gray-800: #333333 !default; // Antikode - $body-color
// $gray-900: #191919 !default; // Antikode
// $black:    #000000 !default;

/* Slate Gray */
$white: #fff;
$gray-10: #fcfcfc !default; // Antikode
$gray-20: #fafafa !default; // Antikode
$gray-50: #f1f3f4 !default; // Antikode
$gray-70: #e2e6e9 !default; // Antikode
$gray-100: #e9ecee !default; // Antikode
$gray-200: #c8ced5 !default; // Antikode
$gray-300: #b2bbc3 !default; // Antikode
$gray-400: #9ca7b2 !default; // Antikode
$gray-500: #7b8a99 !default; // Antikode
$gray-600: #677685 !default; // Antikode
$gray-700: #4e5964 !default; // Antikode
$gray-800: #343b43 !default; // Antikode - $body-color
$gray-900: #1a1e22 !default; // Antikode
$black: #000001 !default;

$gray-2: #4f4f4f !default; // Eatlah
$gray-3: #828282 !default; // Eatlah

$grays: (
  "10": $gray-10,
  // Antikode
  "20": $gray-20,
  // Antikode
  "50": $gray-50,
  // Antikode
  "70": $gray-70,
  // Antikode
);
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

// Main Colors
$blue: #18448a !default;
$blue-dark: #01274e !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
// $red: #ff3a44 !default; // XL Satu
$orange: #fd7e14 !default;
$green: #00d2a0 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$golden: #cba67d !default;
$red: #bb283a !default; //Eatlah
$dark-red: #5b171c !default; //Eatlah
$yellow: #f9aa11 !default; //Eatlah
$ivory: #f5f2ed !default; //Eatlah
$bg-primary: rgba(245, 242, 237, 1); //Eatlah
$light-dark: rgba(91, 23, 28, 0.2); //Eatlah
$gold: #b97a00 !default; //Eatlah

// Brand Colors
$purple-instagram: #dd2a7b !default; // Antikode
$blue-twitter: #00acee !default; // Antikode
$blue-facebook: #1877f2 !default; // Antikode
$blue-linkedin: #0077b5 !default; // Antikode
$red-youtube: #e62117 !default; // Antikode
$red-pinterest: #e60023 !default; // Antikode
$green-whatsapp: #25d366 !default; // Antikode
$pink-tiktok: #ee1d52 !default; // Antikode

$colors: (
  "golden": $golden,
  // Antikode
  "gold": $gold,
  // eatlah
);
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
  ),
  $colors
);

$primary: $dark-red;
$secondary: $green;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-20; // Antikode
$dark: $gray-900; // Antikode

$theme-colors: (
  "gray-10": $gray-10,
  // Antikode
  "gray-20": $gray-20,
  // Antikode
  "gray-50": $gray-50,
  // Antikode
  "gray-70": $gray-70,
  // Antikode
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "black": $black,
  "white": $white,
  "gray-2": $gray-2,
  "gray-3": $gray-3,
);
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "bg-primary": $bg-primary,
    "light-dark": $light-dark,
    "dark-red": $dark-red,
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;

// Characters which are escaped by the escape-svg function
$escaped-characters: (("<", "%3c"), (">", "%3e"), ("#", "%23"));

// Body Color
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $gray-800; // Antikode
$body-color-secondary: $gray-400; // Antikode

// Links Color
//
// Style anchor elements.

$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none; // Antikode
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;
