.error-message {
  font-size: 0.6rem;
  padding-left: 0.01rem;
  padding-top: 0.2rem;
  letter-spacing: 0.5px;
  font-weight: 100;
  color: var(--red) !important;
}

.border-error {
  border: 0.75px solid var(--red) !important;
  input {
    &:focus {
      border: none;
    }
  }
}

.label-input {
  font-size: 12px !important;
  margin-bottom: 6px !important;
}

.input-staff {
  width: 100% !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  padding: 15px 12px;
  border: 1px solid var(--gray-100) !important;
  &:focus {
    outline: none !important;
  }
}

.text-label {
  font-weight: 100 !important;
  color: #828282;
  letter-spacing: 0.5px;
}

.text-voucher {
  font-size: 0.75rem;
  color: var(--dark-red);
  margin-top: 2px;
}

.dynamic-voucher-wrapper {
  display: flex;
  .dynamic-voucher {
    flex: 1;
  }
  .icon-wrapper {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: end;
    i {
      font-size: 1.25rem;
    }
  }
}

.form-control-wrapper {
  &.prefix {
    input {
      padding-left: 3.125rem;
    }
  }
  .prefix-content {
    font-size: 14px !important;
    position: absolute;
    top: 32%;
    padding-left: 1rem;
  }
  &.suffix {
    input {
      padding-right: 0rem;
    }
  }
  .suffix-content {
    font-size: 14px !important;
    position: absolute;
    top: 32%;
    right: 0.75rem;
    // padding-right: 1rem;
  }
}

.checkbox-input {
  margin-bottom: 1rem;
  display: flex;
  gap: 10px;
  align-items: center;
  input[type="checkbox"] {
    accent-color: var(--dark-red);
    border-radius: 0 !important;
    height: 1.125rem;
    width: 1.125rem;
  }
  .label {
    font-size: 14px !important;
  }
}

input {
  font-size: 1rem !important;
}